import http from "@/services/http-common";

class LogFeErrorsService {
  // payload should be a type that is mappable to a JSON object
  log(payload: Record<string, unknown>): Promise<unknown> {
    return http.post("/log-fe-errors", payload);
  }
}

export default new LogFeErrorsService();
