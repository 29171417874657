
import { Options, Vue } from "vue-class-component";
import TrackingService from "@/services/TrackingService";

@Options({
  data() {
    return {
      modalIsClosed: false,
    };
  },
  methods: {
    acceptCookie() {
      this.$store.dispatch("acceptCookieConsent").then(() => {
        this.hideModal();
      });
      TrackingService.track(this.$route.path).catch((e: Error) => {
        console.log(e);
      });
    },
    rejectCookie() {
      this.$store.dispatch("rejectCookieConsent").then(() => {
        this.hideModal();
      });
    },
    hideModal() {
      this.modalIsClosed = true;
      setTimeout(() => {
        this.showGoogleReCaptchaBadge();
      }, 2500);
    },
    showModal() {
      this.modalIsClosed = false;
      setTimeout(() => {
        this.hideGoogleReCaptchaBadge();
      }, 2500);
    },
    showGoogleReCaptchaBadge() {
      const googleReCaptchaElement = document.getElementsByClassName(
        "grecaptcha-badge"
      )[0] as HTMLElement | null;
      if (!googleReCaptchaElement) {
        return;
      }
      if (!this.$store.getters.hasAcceptedCookie) {
        googleReCaptchaElement.style.display = "none";
        return;
      }
      googleReCaptchaElement.style.display = "block";
      googleReCaptchaElement.style.visibility = "visible";
    },
    hideGoogleReCaptchaBadge() {
      const googleReCaptchaElement = document.getElementsByClassName(
        "grecaptcha-badge"
      )[0] as HTMLElement | null;
      if (!googleReCaptchaElement) {
        return;
      }
      if (!this.$store.getters.hasAcceptedCookie) {
        googleReCaptchaElement.style.display = "none";
        return;
      }
      googleReCaptchaElement.style.display = "block";
      googleReCaptchaElement.style.visibility = "hidden";
    },
  },
  computed: {
    hasAcceptedCookie(): boolean {
      return this.$store.getters.hasAcceptedCookie;
    },
    isModalShown(): boolean {
      return !this.modalIsClosed;
    },
    isVisible(): boolean {
      return this.isModalShown;
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  mounted() {
    if (!this.$store.getters.hasAcceptedCookie) {
      this.showModal();
    }
    if (this.$store.getters.hasAcceptedCookie) {
      this.hideModal();
    }
  },
})
export default class CookieModal extends Vue {
  hasAcceptedCookie!: boolean;
}
