
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    recommendation: {},
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
})
export default class Recommendation extends Vue {
  recommendation!: unknown;
}
