<template>
  <div
    v-if="isModalShown"
    class="
      fixed
      z-20
      bottom-0
      right-0
      w-full
      md:w-112
      h-80
      sm:h-64
      md:h-76
      pl-6
      pr-7
      py-4
      bg-gray-800
      text-gray-100
      border-t-2 border-gray-700
      md:rounded-tl-md md:border-t-2 md:border-l-2 md:border-gray-400
      text-xs text-justify
    "
  >
    <div class="pb-2 text-md tracking-widest font-semibold">
      <span class="cursor-pointer" @click="hideModal()"
        >[<span class="text-lg hover:text-yellow-400">x</span>]</span
      >
    </div>
    Using cookies and analyzing IP addresses allow us to deliver and improve our
    web content and to provide you with a better experience. That means this
    website uses cookies and collects IP address for the purposes mentioned in
    the
    <router-link class="link-for-dark-bg" target="_blank" to="/cookie"
      >cookie policy</router-link
    >.

    <br /><br />By accepting this, I agree that this website may use cookies and
    my IP address to collect individual statistics and to provide me with
    personalized content, offers, and ads subject to the
    <router-link class="link-for-dark-bg" target="_blank" to="/privacy"
      >privacy policies</router-link
    >
    and the
    <router-link class="link-for-dark-bg" target="_blank" to="/terms"
      >terms of service</router-link
    >. This website may also use
    <router-link class="link-for-dark-bg" target="_blank" to="/cookie"
      >third-party services</router-link
    >
    for this purpose. I am aware that I can revoke my consent at any time by
    either opening this modal again or visiting the
    <router-link class="link-for-dark-bg" to="/opt-out">opt-out</router-link>
    page.
    <form>
      <div class="flex pt-8 pr-2">
        <div
          class="cursor-pointer flex-grow-0 hover:text-yellow-400"
          @click="acceptCookie()"
        >
          Yes, I agree.
        </div>
        <div class="flex-auto"></div>
        <div
          class="cursor-pointer flex-grow-0 hover:text-yellow-400"
          @click="rejectCookie()"
        >
          No, thanks!
        </div>
      </div>
    </form>
  </div>
  <div
    @click="showModal()"
    :class="[
      isDarkMode ? 'bg-gray-800 md:bg-gray-800' : 'bg-gray-200 md:bg-gray-800',
    ]"
    class="
      fixed
      z-10
      bottom-0
      right-0
      px-4
      pt-4
      pb-5
      rounded-tl-sm
      border-gray-100
      md:px-4 md:py-3 md:rounded-tl-md md:border-t-2 md:border-gray-400
      border-l-2
      cursor-pointer
    "
  >
    <font-awesome-icon
      :class="{ invisible: isVisible }"
      class="text-yellow-400 text-base text-4xl md:text-sm"
      :icon="['fa', 'cookie']"
    />
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
import TrackingService from "@/services/TrackingService";

@Options({
  data() {
    return {
      modalIsClosed: false,
    };
  },
  methods: {
    acceptCookie() {
      this.$store.dispatch("acceptCookieConsent").then(() => {
        this.hideModal();
      });
      TrackingService.track(this.$route.path).catch((e: Error) => {
        console.log(e);
      });
    },
    rejectCookie() {
      this.$store.dispatch("rejectCookieConsent").then(() => {
        this.hideModal();
      });
    },
    hideModal() {
      this.modalIsClosed = true;
      setTimeout(() => {
        this.showGoogleReCaptchaBadge();
      }, 2500);
    },
    showModal() {
      this.modalIsClosed = false;
      setTimeout(() => {
        this.hideGoogleReCaptchaBadge();
      }, 2500);
    },
    showGoogleReCaptchaBadge() {
      const googleReCaptchaElement = document.getElementsByClassName(
        "grecaptcha-badge"
      )[0] as HTMLElement | null;
      if (!googleReCaptchaElement) {
        return;
      }
      if (!this.$store.getters.hasAcceptedCookie) {
        googleReCaptchaElement.style.display = "none";
        return;
      }
      googleReCaptchaElement.style.display = "block";
      googleReCaptchaElement.style.visibility = "visible";
    },
    hideGoogleReCaptchaBadge() {
      const googleReCaptchaElement = document.getElementsByClassName(
        "grecaptcha-badge"
      )[0] as HTMLElement | null;
      if (!googleReCaptchaElement) {
        return;
      }
      if (!this.$store.getters.hasAcceptedCookie) {
        googleReCaptchaElement.style.display = "none";
        return;
      }
      googleReCaptchaElement.style.display = "block";
      googleReCaptchaElement.style.visibility = "hidden";
    },
  },
  computed: {
    hasAcceptedCookie(): boolean {
      return this.$store.getters.hasAcceptedCookie;
    },
    isModalShown(): boolean {
      return !this.modalIsClosed;
    },
    isVisible(): boolean {
      return this.isModalShown;
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
  mounted() {
    if (!this.$store.getters.hasAcceptedCookie) {
      this.showModal();
    }
    if (this.$store.getters.hasAcceptedCookie) {
      this.hideModal();
    }
  },
})
export default class CookieModal extends Vue {
  hasAcceptedCookie!: boolean;
}
</script>
