
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    project: {},
  },
})
export default class HobbyProjectItem extends Vue {
  project!: unknown;
}
