import {
  createRouter,
  createWebHashHistory,
  createWebHistory,
  RouteRecordRaw,
} from "vue-router";
import Home from "../views/Home.vue";
import TrackingService from "@/services/TrackingService";
import store from "@/store";
import LogFeErrorsService from "@/services/log-fe-errors-service";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "About me",
    component: Home,
  },
  {
    path: "/articles",
    name: "Articles",
    component: () =>
      import(/* webpackChunkName: "articles" */ "../views/Articles.vue"),
  },
  {
    path: "/articles/:slug",
    name: "Article",
    component: () =>
      import(/* webpackChunkName: "article" */ "../views/Article.vue"),
  },
  {
    path: "/career",
    name: "Career",
    component: () =>
      import(/* webpackChunkName: "career" */ "../views/Career.vue"),
  },
  {
    path: "/certificates",
    name: "Certificates",
    component: () =>
      import(
        /* webpackChunkName: "certificates" */ "../views/Certificates.vue"
      ),
  },
  {
    path: "/contact",
    name: "Contact me",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Contact.vue"),
  },
  {
    path: "/terms",
    name: "Terms",
    component: () =>
      import(
        /* webpackChunkName: "contact" */ "../views/TermsAndConditions.vue"
      ),
  },
  {
    path: "/privacy",
    name: "Privacy policy",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/PrivacyPolicy.vue"),
  },
  {
    path: "/cookie",
    name: "Cookie policy",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/CookiePolicy.vue"),
  },
  {
    path: "/memes-and-art",
    name: "Memes & art",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Memes.vue"),
  },
  {
    path: "/opt-out",
    name: "Opt Out",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/OptOut.vue"),
  },
  {
    path: "/error/404",
    name: "404",
    component: () =>
      import(/* webpackChunkName: "contact" */ "../views/Error404.vue"),
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from) => {
  // If route not matched, call an API to log the error on the backend
  if (to.matched.length === 0) {
    const payload = {
      pathFrom: from.fullPath,
      pathTo: to.fullPath,
      errorCode: 404,
    };
    LogFeErrorsService.log(payload)
      .catch((e: Error) => {
        console.log(e);
      })
      .then(() => {
        router.push({ name: "404" });
      });
  }

  // Google reCaptcha badge
  const protectedByGooglePaths = ["/contact"];
  const googleReCaptchaElement = document.getElementsByClassName(
    "grecaptcha-badge"
  )[0] as HTMLElement | null;
  if (!googleReCaptchaElement) {
    return;
  }
  if (!store.getters.hasAcceptedCookie) {
    googleReCaptchaElement.style.display = "none";
    return;
  }
  if (!protectedByGooglePaths.includes(to.path)) {
    googleReCaptchaElement.style.display = "block";
    googleReCaptchaElement.style.visibility = "hidden";
    return;
  }
  googleReCaptchaElement.style.display = "block";
  googleReCaptchaElement.style.visibility = "visible";
});
router.afterEach((to, from) => {
  // For security reasons, we always track page views
  // if (store.getters.hasAcceptedCookie) {
  TrackingService.track(to.fullPath).catch((e: Error) => {
    console.log(e);
  });
  // }
});

export default router;
