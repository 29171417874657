import { createStore } from "vuex";
import TrackingService from "@/services/TrackingService";
import ConsentService from "@/services/ConsentService";

const initialState = {
  darkMode: false,
  hasAcceptedCookie: false,
  pageTitle: "Pooyan",
};

export default createStore({
  state: initialState,
  mutations: {
    TOGGLE_DARK_MODE(state) {
      state.darkMode = !state.darkMode;
    },
    ACCEPT_COOKIE_CONSENT(state) {
      state.hasAcceptedCookie = true;
    },
    REJECT_COOKIE_CONSENT(state) {
      state.hasAcceptedCookie = false;
    },
  },
  actions: {
    toggleDarkMode(context) {
      context.commit("TOGGLE_DARK_MODE");
    },
    acceptCookieConsent(context) {
      ConsentService.log("cookie", "accepted")
        .then(() => {
          context.commit("ACCEPT_COOKIE_CONSENT");
          localStorage.setItem("hasAcceptedCookie", "Y");
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
    rejectCookieConsent(context) {
      ConsentService.log("cookie", "rejected")
        .then(() => {
          context.commit("REJECT_COOKIE_CONSENT");
          localStorage.setItem("hasAcceptedCookie", "N");
        })
        .catch((e: Error) => {
          console.log(e);
        });
    },
  },
  getters: {
    isDarkMode(state) {
      return state.darkMode;
    },
    hasAcceptedCookie(state) {
      if (localStorage.getItem("hasAcceptedCookie") === "Y") {
        state.hasAcceptedCookie = true;
      }
      return state.hasAcceptedCookie;
    },
    title(state) {
      return state.pageTitle;
    },
  },
  modules: {},
});
