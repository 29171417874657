
import { Options, Vue } from "vue-class-component";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faMoon, faSun } from "@fortawesome/free-solid-svg-icons";
import CookieModal from "@/components/CookieModal.vue";

library.add(faMoon, faSun);

@Options({
  data() {
    return {
      menuOpen: false,
    };
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
    currentYear() {
      return new Date().getFullYear();
    },
  },
  methods: {
    toggleMenu() {
      this.menuOpen = !this.menuOpen;
    },
    toggleDarkMode() {
      this.$store.dispatch("toggleDarkMode").then(() => {
        if (this.isDarkMode) {
          document.documentElement.classList.add("dark");
        } else {
          document.documentElement.classList.remove("dark");
        }
      });
    },
  },
  watch: {
    $route: {
      handler(to, from) {
        document.title = "Pooyan - " + this.currentRouteName || "Pooyan";
      },
    },
  },
  components: {
    CookieModal,
  },
})
export default class App extends Vue {}
