
import { Options, Vue } from "vue-class-component";

interface Skill {
  experience: number;
  name: string;
  type: string;
}

@Options({
  props: {
    skill: {},
    type: {
      type: String,
      required: false,
      default: "",
    },
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
})
export default class SkillBarItem extends Vue {
  skill!: Skill;
  type!: string;

  get getClass(): string {
    let experience = this.skill.experience;
    switch (experience) {
      case 1:
        return "w-" + this.skill.experience + "/5 bg-gray-400";
      case 2:
        return "w-" + this.skill.experience + "/5 bg-gray-400";
      case 3:
        return "w-" + this.skill.experience + "/5 bg-blue-300";
      case 4:
        return "w-" + this.skill.experience + "/5 bg-blue-400";
      case 5:
        return "w-" + this.skill.experience + "/5 bg-green-600";
      default:
        return "w-" + this.skill.experience + "/5 bg-gray-800";
    }
  }
}
