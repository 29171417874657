<template>
  <div
    class="
      flex-initial
      mx-8
      sm:mx-5
      md:mx-4
      mb-5
      w-28
      h-32
      align-middle
      items-center
    "
  >
    <a
      class="inline-block align-middle text-center items-center"
      :href="project.url"
      target="_blank"
    >
      <img
        class="
          h-24
          w-24
          rounded-full
          mb-3
          shadow-md
          opacity-80
          mx-auto
          hover:opacity-100
          transition-opacity
        "
        :src="project.image"
      />
      <div class="w-28 whitespace-nowrap link text-xs font-semibold uppercase">
        {{ project.name }}
      </div>
      <div
        v-if="project.isDeprecated"
        class="
          w-28
          whitespace-nowrap
          link
          text-xs
          font-semibold
          uppercase
          no-underline
          text-gray-400
          dark:text-gray-600
        "
      >
        deprecated
      </div>
    </a>
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    project: {},
  },
})
export default class HobbyProjectItem extends Vue {
  project!: unknown;
}
</script>
