<template>
  <blockquote
    class="
      relative
      pt-4
      pb-6
      p-4
      text-sm
      italic
      border-l-4 border-gray-300
      bg-white
      text-gray-800
      quote
    "
  >
    <div
      class="stylistic-quote-mark top-0 -ml-3 -mt-4 text-gray-300"
      aria-hidden="true"
    >
      &ldquo;
    </div>
    <p class="text-justify leading-8 md:leading-6">
      {{ recommendation.content }}
    </p>
    <cite
      class="absolute mt-3 text-xs font-semibold px-1 py-1 rounded-md"
      :class="[isDarkMode ? 'text-gray-100 bg-gray-900' : 'text-gray-700']"
    >
      <a
        :href="recommendation.url"
        class="hover:bg-blue-500 hover:cursor-pointer"
        >{{ recommendation.authorFirstName }}</a
      ><span class="hidden md:inline-block">
        - {{ recommendation.authorJobTitle }}</span
      >
      @ {{ recommendation.authorCompany }}
    </cite>
    <div
      class="stylistic-quote-mark right-0 fa-rotate-180 text-gray-300 -mt-2"
      aria-hidden="true"
    >
      &ldquo;
    </div>
  </blockquote>
</template>

<style scoped>
.stylistic-quote-mark {
  font-size: 3.2rem;
  @apply font-serif absolute leading-none font-bold;
}
</style>

<script lang="ts">
import { Options, Vue } from "vue-class-component";

@Options({
  props: {
    recommendation: {},
  },
  computed: {
    isDarkMode() {
      return this.$store.getters.isDarkMode;
    },
  },
})
export default class Recommendation extends Vue {
  recommendation!: unknown;
}
</script>
