import http from "@/services/http-common";

class ConsentService {
  log(type: string, status: string): Promise<any> {
    return http.post("/consent", {
      status: status,
      type: type,
    });
  }
}

export default new ConsentService();
